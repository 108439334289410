
:root {
  font-family: 'DM Sans', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* .Toastify__toast-theme--light {
  background: #000 !important;
  color: #fff !important;
}

.Toastify__close-button--light {
  color: white !important;
  opacity: 0.8 !important;
}

.Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 50px !important;
  background: #f0f9fa !important;
  box-shadow: 0 1px 5px 0 #3a9ea5, 0 2px 5px 0 #3a9ea540 !important;
}

.Toastify__toast--success .Toastify__toast-body {
  color: #3a9ea5;
  font-weight: 700;
}

.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 50px !important;
  background: #fae1e2 !important;
  box-shadow: 0 1px 5px 0 #eb5757, 0 2px 5px 0 #eb575740 !important;
}

.Toastify__toast--error .Toastify__toast-body {
  color: #eb5757;
  font-weight: 700;
}

.Toastify__toast button svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  fill: black !important;
} */

.swal2-styled.swal2-default-outline:focus {
  box-shadow: unset !important;
}

.swal2-footer-link {
  text-decoration: none;
  color: rgba(2, 135, 223, 1);
  cursor: pointer;
  font-weight: 400;
  text-transform: uppercase;
}

.swal2-modal {
  border-radius: 20px !important;
}

.swal2-image {
  margin: 1rem auto;

}

.swal2-close {
  color: #000 !important;
}

.swal2-close:hover {
  color: rgba(0, 0, 0, 0.4) !important;
}

.swal2-title {
  padding:0  !important;
  padding-top:.5rem !important;
  /* margin: 1rem 0 !important; */
}

.swal2-title span {
  position: relative;
  font-size:28px;
}

.swal2-title span:before {
  position: absolute;
  content: '';
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 5px);
  width: 120px;
}

.swal2-icon-error .swal2-title span:before {
  background: #f27474;
}

.swal2-icon-success .swal2-title span:before {
  background: #a5dc86;
}

.swal2-actions {
  margin-top: 2rem !important;
}

.swal2-cancel {
  border-radius: 40px !important;
  background: rgba(0, 0, 0, 0.8)!important;
}

.swal2-confirm {
  order: 1;
  background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%) !important;
  width: 200px;
  border-radius: 40px !important;
  font-weight: 700;
  font-size: 16px;
}

.swal2-confirm:focus {
  box-shadow: unset !important;
}

.swal2-confirm:hover {
  opacity: 0.8;
}

.swal2-icon.swal2-success .swal2-success-ring {
  position: relative;
  z-index: 2 !important;
  background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%) !important;
  border-radius: 50%;
  border-color: #fff !important;
}

.swal2-success-line-long,
.swal2-success-line-tip {
  background: #fff !important;
  z-index: 3 !important;
}

/* .swal2-icon.swal2-success .swal2-success-ring:before {
  content: '';
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%) !important;

  border-radius: 50%;
} */
